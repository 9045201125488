import { appProvider } from '@/app-providers'
import {
  FarmServiceContributeInfo,
  FarmServiceExtraConfig,
  FarmServicePoolConfig,
  FarmServiceSolanaContract,
  FarmServiceUserInfo,
} from '@/blockchainHandlers/farm-service-solana'
import { splPriceStore } from '@/blockchainHandlers/spl-token-helper'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { Zero } from '@/constants'
import { strEquals } from '@/helpers'
import { bigNumberHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { WalletSignTransactionError } from '@solana/wallet-adapter-base'
import { PublicKey } from '@solana/web3.js'
import { keyBy, last } from 'lodash-es'
import { action, computed, observable, reaction, runInAction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment, { duration } from 'moment'
import { Subject, Subscription, timer } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'

const SecondsPerYear = 60 * 60 * 24 * 365
export class StakingServiceItemViewModel {
  @observable pool: FarmServicePoolConfig = {} as any
  @observable showApyConfigDialog = false

  @observable stakeDialogInput = ''
  @observable isShowStakeDialog = false
  @observable isDialogStaking = false
  @observable extendTimeOnly = false
  @observable isDialogLoading = false

  @observable harvesting = false

  @observable poolRewardBalance = FixedNumber.from('0')
  @observable ownerUserRewardBalance = FixedNumber.from('0') // for fund
  @observable userLPBalance = FixedNumber.from('0')
  @observable lockInDaysInput = 0
  @observable tokenPrice = FixedNumber.from('0')
  @observable selectedRewardConfig: FarmServiceExtraConfig | undefined = undefined

  constructor(pool: FarmServicePoolConfig) {
    this.pool = pool
    this.getTokenPrice()
  }

  @asyncAction *getTokenPrice() {
    if (this.pool.type === 'main' && this.pool.tokenAddress) {
      this.tokenPrice = yield splPriceStore
        .getRaydiumPriceByAddress(new PublicKey(this.pool.tokenAddress))
        .then((x) => FixedNumber.from(`${x}`))
    } else {
      this.tokenPrice = FixedNumber.from('0.1')
    }
  }

  private _unsubcrible = new Subject()
  @action startInterval() {
    this.stopInterval()
    if (walletStore.account && walletStore.solanaConnected) {
      timer(0, 60000)
        .pipe(takeUntil(this._unsubcrible))
        .subscribe(async () => {
          const balance = await stakingServiceContract.getUserTokenBalance(this.pool, walletStore.account)
          runInAction(() => (this.userLPBalance = balance))
        })
      timer(0, 120000)
        .pipe(
          takeUntil(this._unsubcrible),
          filter((x) => this.isPoolOwner)
        )
        .subscribe(() => {
          this.getPoolFundInfos()
        })
      timer(5000, 5000)
        .pipe(takeUntil(this._unsubcrible))
        .subscribe(() => stakingServiceViewModel.loadContributeInfos())
    }
  }

  @asyncAction *getPoolFundInfos() {
    const poolBalance = yield stakingServiceContract.getRewardTokenBalance(this.pool)
    const userRewardBalance = yield stakingServiceContract.getUserRewardTokenBalance(this.pool, walletStore.account)
    this.poolRewardBalance = poolBalance
    this.ownerUserRewardBalance = userRewardBalance
  }

  @action stopInterval() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._unsubcrible = new Subject()
  }

  @asyncAction *harvest() {
    this.harvesting = true
    try {
      yield stakingServiceContract.harvest(this.pool, this.userInfo.contributeId!, walletStore.account)
      stakingServiceViewModel.loadContributeInfos(true)
      stakingServiceViewModel.loadPoolInfors()
      snackController.success('Harvest successed')
    } catch (error: any) {
      snackController.error(error.msg || error.message)
    }
    this.harvesting = false
  }

  @observable userCreating = false;
  @asyncAction *confirmCreateUser() {
    try {
      this.userCreating = true
      yield stakingServiceContract.createUser(this.pool, walletStore.account)
      stakingServiceViewModel.loadUserInfos(true)
    } catch (error: any) {
      snackController.error(error.msg || error.message)
      if (error instanceof WalletSignTransactionError) {
        this.showUpdateApyDialog = false
      }
    } finally {
      this.userCreating = false
    }
  }

  @action.bound requestStakeLP(extendonly = false) {
    this.stakeDialogInput = ''
    this.isShowStakeDialog = true
    this.isDialogStaking = true
    this.extendTimeOnly = extendonly
    if (extendonly) {
      this.stakeDialogInput = ''
    }
    this.lockInDaysInput = this.defaultLockInDays
    if (this.userLockDuration.asSeconds() > 0) {
      this.selectedRewardConfig = [...this.rewardConfigs]
        .reverse()
        .find((x) => this.userLockDuration.asSeconds() >= x.duration.asSeconds())
    } else {
      this.selectedRewardConfig = last(this.rewardConfigs)
    }
  }

  @action.bound requestUnstakeLP() {
    this.stakeDialogInput = ''
    this.isShowStakeDialog = true
    this.isShowStakeDialog = true
    this.isDialogStaking = false
  }

  @asyncAction *confirm() {
    this.isDialogLoading = true
    try {
      if (this.isDialogStaking) {
        yield stakingServiceContract.stake(
          this.pool,
          this.userInfo.contributeId!,
          walletStore.account,
          this.validStakeDialogInput.toString(),
          this.selectedRewardConfig!.duration.asSeconds()
        )
        snackController.success('Stake WAG successed')
      } else {
        yield stakingServiceContract.unstake(
          this.pool,
          this.userInfo.contributeId!,
          walletStore.account,
          this.validStakeDialogInput.toString()
        )
        snackController.success('Unstake WAG successed')
      }
      stakingServiceViewModel.loadPoolInfors()
      stakingServiceViewModel.loadContributeInfos(true)
      this.stakeDialogInput = ''
      this.isShowStakeDialog = false
    } catch (error: any) {
      console.error(error)
      snackController.error(error.msg || error.message)
      if (error instanceof WalletSignTransactionError) {
        this.isShowStakeDialog = false
      }
    } finally {
      this.isDialogLoading = false
    }
  }

  @action.bound onMultipleChange(multiple = 0) {
    this.stakeDialogInput = FixedNumber.from(`${multiple}`).mulUnsafe(this.maxDialogStakeBalance).toString()
  }

  @action.bound cancelStakeDialog() {
    this.isShowStakeDialog = false
  }
  @action.bound setApyConfigDialog(val) {
    this.showApyConfigDialog = val
  }

  @action.bound changeStakeDialogInput(input) {
    this.stakeDialogInput = input
  }
  @action updatePool(pool: FarmServicePoolConfig) {
    this.pool = pool
  }
  @action.bound selectRewardConfig(config) {
    this.selectedRewardConfig = config
  }

  // update APY
  @observable showUpdateApyDialog = false
  @observable newApr = ''
  @observable newTokensPerSecond = ''
  @observable tokensPerSecondRequesting = false
  @action.bound setUpdateApyDialog(val) {
    this.newApr = ''
    this.newTokensPerSecond = ''
    this.showUpdateApyDialog = val
  }
  @action.bound changeNewApy(val) {
    this.newApr = val
    let valiNewApr = FixedNumber.from('0')
    try {
      valiNewApr = FixedNumber.from(val)
    } catch {
      //
    }
    this.newTokensPerSecond = valiNewApr
      .mulUnsafe(this.totalStakedAmount)
      .divUnsafe(FixedNumber.from(`${SecondsPerYear}`))
      .divUnsafe(FixedNumber.from('100'))
      .toString()
  }
  @action.bound changeNewTokensPerSecond(val) {
    this.newTokensPerSecond = val
    this.newApr = this.newTokensPerYear.divUnsafe(this.totalStakedAmount).mulUnsafe(FixedNumber.from('100')).toString()
  }
  @asyncAction *confirmChangeTokensPerSecond() {
    try {
      this.tokensPerSecondRequesting = true
      yield stakingServiceContract.updateTokensPerSecond(this.pool, this.validNewTokensPerSecond, walletStore.account)
      snackController.success('APR changed')
      this.showUpdateApyDialog = false
      stakingServiceViewModel.loadPoolInfors()
    } catch (error: any) {
      snackController.error(error.msg || error.message)
      if (error instanceof WalletSignTransactionError) {
        this.showUpdateApyDialog = false
      }
    } finally {
      this.tokensPerSecondRequesting = false
    }
  }

  @computed get validNewTokensPerSecond() {
    try {
      return FixedNumber.from(this.newTokensPerSecond)
    } catch {
      return Zero
    }
  }
  @computed get newTokensPerYear() {
    return this.validNewTokensPerSecond.mulUnsafe(FixedNumber.from(`${SecondsPerYear}`))
  }
  // end update APY

  // fund reward dialog
  @observable showFundRewardDialog = false
  @observable fundRewardInput = ''
  @observable funding = false
  @action.bound setShowFundReawrdDialog(val) {
    this.fundRewardInput = ''
    this.showFundRewardDialog = val
  }
  @action.bound changeFundRewardInput(val) {
    this.fundRewardInput = val
  }
  @asyncAction *confirmFundReawrd() {
    try {
      this.funding = true
      yield stakingServiceContract.fundReward(this.pool, walletStore.account, this.validFundRewardInput)
      snackController.success('Funded successfully.')
      this.showFundRewardDialog = false
      this.getPoolFundInfos()
    } catch (error: any) {
      snackController.error(error.msg || error.message)
      if (error instanceof WalletSignTransactionError) {
        this.showFundRewardDialog = false
      }
    } finally {
      this.funding = false
    }
  }
  @computed get validFundRewardInput() {
    try {
      return FixedNumber.from(`${this.fundRewardInput}`)
    } catch (er) {
      return Zero
    }
  }
  // end fund reawrd

  @computed get validStakeDialogInput() {
    try {
      return FixedNumber.from(this.stakeDialogInput)
    } catch {
      return Zero
    }
  }

  @computed get isStaked() {
    return bigNumberHelper.gt(this.stakedLP, FixedNumber.from('0'))
  }

  @computed get maxDialogStakeBalance() {
    return this.isDialogStaking ? this.userLPBalance : this.stakedLP
  }

  @computed get lockInDays() {
    return this.userLockDuration.asDays()
  }

  @computed get defaultLockInDays() {
    return this.userLockDuration.asDays()
  }
  @computed get validLockDuration() {
    return this.defaultLockInDays <= this.lockInDaysInput
  }

  @computed get canUnstakeTime() {
    if (this.lastStakeTime) {
      return this.lastStakeTime.clone().add(this.userLockDuration)
    }
    return null
  }
  @computed get userExtraReward() {
    const config = [...this.rewardConfigs]
      .reverse()
      .find((x) => this.userLockDuration.asSeconds() >= x.duration.asSeconds())
    return config?.extraPercentage || Zero
  }

  @computed get canHarvest() {
    return bigNumberHelper.gt(this.rewardAmount, Zero)
  }

  @computed get isSelectedBest() {
    return this.selectedRewardConfig === last(this.rewardConfigs)
  }
  @computed get tvl() {
    return this.totalStakedAmount.mulUnsafe(this.tokenPrice)
  }

  @computed get baseOneYearReward() {
    const totalAmount = FixedNumber.from(`${this.totalUserStakedAmount || 0}`)
    const reward = totalAmount.mulUnsafe(this.apy).divUnsafe(FixedNumber.from('100'))
    return reward
  }

  @computed get oneYearReward() {
    const selectedConfig = this.selectedRewardConfig
    const bonus = this.baseOneYearReward
      .mulUnsafe(selectedConfig?.extraPercentage || Zero)
      .divUnsafe(FixedNumber.from('100'))
    return this.baseOneYearReward.addUnsafe(bonus)
  }

  @computed get validDialogInputAmount() {
    try {
      const amount = this.validStakeDialogInput
      if (this.isDialogStaking && !this.validLockDuration) return false
      if (!this.isDialogStaking && this.canUnstakeTime && this.canUnstakeTime.isAfter(moment())) return false
      if (amount.isNegative()) return false
      if (amount.isZero()) {
        if (this.isDialogStaking) {
          return (
            !this.stakedLP.isZero() &&
            this.userLockDuration.asSeconds() !== (this.selectedRewardConfig?.duration.asSeconds() || 0)
          )
        } else {
          return false
        }
      }
      if (this.isDialogStaking) {
        return bigNumberHelper.lte(amount, this.userLPBalance)
      } else {
        return bigNumberHelper.lte(amount, this.stakedLP)
      }
    } catch (error) {
      return false
    }
  }

  @computed get stakeLockedUntil() {
    return moment().add(this.selectedRewardConfig?.duration.asSeconds() || 0, 'second')
  }

  @computed get requiredChainId() {
    return process.env.VUE_APP_FARM_NETWORK === 'production' ? 101 : 103
  }

  @computed get maxApy() {
    return this.apy.mulUnsafe(FixedNumber.from('2'))
  }

  @computed get yourApy() {
    const config = [...this.rewardConfigs]
      .reverse()
      .find((x) => this.userLockDuration.asSeconds() >= x.duration.asSeconds())
    return this.apy.mulUnsafe(config?.boost || FixedNumber.from('1'))
  }

  @computed get isUserLocked1Year() {
    return this.userLockDuration.asSeconds() === 365 * 24 * 60 * 60
  }

  @computed get rewardConfigs() {
    return this.pool.extraConfigs
  }

  @computed get totalUserStakedAmount() {
    return this.isDialogStaking
      ? this.stakedLP.addUnsafe(this.validStakeDialogInput).toString()
      : this.stakedLP.subUnsafe(this.validStakeDialogInput).toString()
  }

  // Get USER & Contribute INFO
  @computed get contributeInfo() {
    return stakingServiceViewModel.userContributeByPoolName[this.pool.name]
  }
  @computed get userInfo() {
    return stakingServiceViewModel.userByPoolName[this.pool.name]
  }

  @computed get userCreated() {
    return this.userInfo?.created
  }

  @computed get stakedLP() {
    return this.contributeInfo?.amount || FixedNumber.from('0')
  }

  @computed get rewardAmount() {
    return this.contributeInfo?.pendingReward || FixedNumber.from('0')
  }

  @computed get lastStakeTime() {
    return this.contributeInfo?.lastStakeTime
  }

  @computed get userLockDuration() {
    return this.contributeInfo?.lockDuration || duration(0, 's')
  }

  @computed get isPoolOwner() {
    return strEquals(walletStore.account, this.pool.authority) || strEquals(walletStore.account, this.pool.admin)
  }

  // POOL RAW INFO
  @computed get id() {
    return this.pool.name
  }
  @computed get tokensPerSecond() {
    return this.pool.tokensPerSecond || FixedNumber.from('0')
  }
  @computed get tokensPerYear() {
    return this.pool.tokensPerSecond?.mulUnsafe(FixedNumber.from(`${60 * 60 * 24 * 365}`)) || FixedNumber.from('0')
  }
  @computed get logo() {
    return this.pool.logo
  }
  @computed get cover() {
    return this.pool.cover
  }
  @computed get token() {
    return this.pool.token
  }
  @computed get getDepositToken() {
    return this.pool.getDepositToken
  }
  @computed get rewardToken() {
    return this.pool.reward
  }
  @computed get title() {
    return this.pool.title
  }
  @computed get description() {
    return this.pool.description
  }
  @computed get totalStakedAmount() {
    return this.pool.totalStakedAmount || FixedNumber.from('0')
  }
  @computed get apy() {
    return this.pool.apy || FixedNumber.from('0')
  }
  @computed get deposit() {
    return FixedNumber.from('0')
  }
  @computed get earn() {
    return FixedNumber.from('0')
  }
  @computed get informations() {
    return Object.entries(this.pool.informations)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => ({ type: key, link: value }))
  }
  @computed get liveAt() {
    return this.pool.liveAt || moment(0)
  }
  @computed get stakeEnabled() {
    return appProvider.currentTime.isSameOrAfter(this.liveAt)
  }
}

const stakingServiceContract = FarmServiceSolanaContract.getInstance()
class StakingServiceViewModel {
  @observable pools: StakingServiceItemViewModel[] = []
  @observable userInfos: FarmServiceUserInfo[] = []
  @observable userContributeInfos: FarmServiceContributeInfo[] = []
  @observable selectedPool: StakingServiceItemViewModel | null = null
  private _loaded = false
  _userInfoInterval?: Subscription;

  @asyncAction *loadIfNeed() {
    if (!this._loaded) {
      this._loaded = true
      yield stakingServiceContract.loadIfNeed()
      yield this.loadPoolInfors()
      reaction(
        () => walletStore.account,
        () => this.handleAccountChanged(),
        { fireImmediately: true }
      )
    }
  }

  @asyncAction *loadPoolInfors() {
    const pools: FarmServicePoolConfig[] = yield stakingServiceContract.getPoolInfors(true)
    if (!this.pools.length) {
      this.pools = pools.map((x) => new StakingServiceItemViewModel(x))
    } else {
      pools.forEach((p) => this.poolByIds[p.name]?.updatePool(p))
    }
  }

  @asyncAction *loadPool(poolId: string) {
    yield this.loadIfNeed()
    this.selectedPool = this.poolByIds[poolId]
    this.selectedPool?.startInterval()
    if (!this.selectedPool) {
      snackController.error(`Pool ${poolId} might not be exist`)
      appProvider.router.replace('/dashboard')
    }
  }

  @action handleAccountChanged() {
    this._userInfoInterval?.unsubscribe()
    if (walletStore.account && walletStore.solanaConnected) {
      this.loadUserInfos(true)
      this._userInfoInterval = timer(0, 60000).subscribe(() => {
        this.loadContributeInfos(true)
      })
    } else {
      this.userInfos = []
    }
    this.selectedPool?.startInterval()
  }

  @observable userInforsLoading = false;
  @asyncAction *loadUserInfos(force = false) {
    this.userInforsLoading = true
    try {
      const userInfos = yield stakingServiceContract.getUserInfors(walletStore.account, force)
      this.userInfos = userInfos
    } catch {
      //
    }
    this.userInforsLoading = false
  }

  @asyncAction *loadContributeInfos(force = false) {
    const contributes = yield stakingServiceContract.getUserContributeInfos(walletStore.account, force)
    this.userContributeInfos = contributes
  }
  @computed get poolByIds() {
    return keyBy(this.pools, (x) => x.id)
  }
  @computed get userContributeByPoolName() {
    return keyBy(this.userContributeInfos, (x) => x.pool.name)
  }
  @computed get userByPoolName() {
    return keyBy(this.userInfos, (x) => x.pool.name)
  }
}

export const stakingServiceViewModel = new StakingServiceViewModel()

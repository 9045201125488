import { bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import * as anchor from '@project-serum/anchor'
import { BN, Program, Provider, web3 } from '@project-serum/anchor'
import { SendTxRequest } from '@project-serum/anchor/dist/cjs/provider'
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, u64 } from '@solana/spl-token'
import { ENV as SOL_CHAINID } from '@solana/spl-token-registry'
import { PublicKey } from '@solana/web3.js'
import { cloneDeep, round } from 'lodash'
import { reaction } from 'mobx'
import moment, { Duration, duration, Moment } from 'moment'
import { blockchainHandler } from '.'
import farmServiceIDL from './abis/farm-service.idl.json'
import { SlpTokenProgram } from './slp-token-contract'
import { FarmService } from './types/farm_service'
const utf8 = anchor.utils.bytes.utf8
const { SystemProgram, Keypair, Transaction } = web3

const FARM_SERVICE_ID = new PublicKey('9dUpifmBNqGBD3SGrXdPsKcjJXo4SQt6HAcGo1VgV12E')

export interface FarmServicePoolConfig {
  type: 'dev' | 'main'
  liveAt: moment.Moment
  getDepositToken: string
  name: string
  token: string
  reward: string
  publicKey: PublicKey
  logo: string
  cover: string
  title: string
  subTitle: string
  description: string
  informations: { medium?: string; twitter?: string; telegram?: string; web?: string; discord?: string }
  extraConfigs: FarmServiceExtraConfig[]
  tokenAddress?: string
  authority?: string
  admin?: string
  apy?: FixedNumber
  tokensPerSecond?: FixedNumber
  totalStakedAmount?: FixedNumber
  splToken?: SlpTokenProgram
  decimals: 9
  rewardSplToken?: SlpTokenProgram
  rewardDecimals: 9
}

export interface FarmServiceExtraConfig {
  duration: moment.Duration
  inseconds: number
  extraPercentage: FixedNumber
  text: string
  tooltip: string
  boost: FixedNumber
}

export interface FarmServiceUserInfo {
  pool: FarmServicePoolConfig
  created: boolean
  contributeId?: number
}
export interface FarmServiceContributeInfo {
  pool: FarmServicePoolConfig
  lastStakeTime?: moment.Moment
  lockDuration: moment.Duration
  amount: FixedNumber
  pendingReward: FixedNumber
  id?: number
}
let mainContract: FarmServiceSolanaContract
let devContract: FarmServiceSolanaContract
export class FarmServiceSolanaContract {
  program: anchor.Program<FarmService>
  tokenPrograms: { [id: string]: SlpTokenProgram } = {}
  userInfos: FarmUserAccount[] = [] // current user only
  userContributeInfos: FarmContributeAccount[] = [] // current user only
  stateAccount?: FarmStateAccount
  poolAccounts: FarmPoolAccount[] = []
  poolConfigs: FarmServicePoolConfig[] = []

  private constructor(public provider: Provider, public chainId: SOL_CHAINID, list: any[]) {
    this.program = new Program(farmServiceIDL as any, FARM_SERVICE_ID.toString(), provider)
    this.poolConfigs = list.map((m) => {
      return {
        ...m,
        liveAt: moment(m.liveAt),
        publicKey: new PublicKey(m.poolAddress),
        extraConfigs: m.extraConfigs.map((c) => {
          const lockDuration = duration(c.duration, 'second')
          const inmonths = round(lockDuration.asMonths())
          const inweeks = round(lockDuration.asWeeks())
          const indays = round(lockDuration.asDays())
          const inseconds = round(lockDuration.asSeconds())
          let text = ''
          let tooltip = ''
          if (inmonths > 0) {
            text = `${inmonths}M`
            tooltip = `${inmonths} month(s)`
          } else if (inweeks > 0) {
            text = `${inweeks}W`
            tooltip = `${inweeks} week(s)`
          } else if (indays > 0) {
            text = `${indays}D`
            tooltip = `${indays} day(s)`
          } else if (inseconds > 0) {
            text = `${inseconds}S`
            tooltip = `${inseconds} second(s)`
          } else {
            text = `Flex.`
            tooltip = `Flexible`
          }
          // if (inmonths === 1) tooltip = tooltip.replace('months', 'month')
          // if (inmonths >= 12) {
          //   text = `1Y`
          //   tooltip = `1 year`
          // }
          const extraPercentage = FixedNumber.from(`${c.extraPercentage}`)
          const boost = FixedNumber.from('100').addUnsafe(extraPercentage).divUnsafe(FixedNumber.from('100'))
          return {
            duration: lockDuration,
            inseconds: lockDuration.asSeconds(),
            extraPercentage,
            boost,
            text,
            tooltip,
          } as FarmServiceExtraConfig
        }),
      }
    })
    reaction(
      () => walletStore.account,
      () => {
        if (walletStore.account && walletStore.chainId === chainId) {
          this.injectProvider(walletStore.connectedSolProvider)
        }
      },
      { fireImmediately: true }
    )
  }

  static getInstance(provider?: Provider) {
    if (!mainContract) {
      const pools = require('./resources/farm-service-list.json')
      const mainProvider = blockchainHandler.getSolanaConfig(SOL_CHAINID.MainnetBeta)
      const devProvider = blockchainHandler.getSolanaConfig(SOL_CHAINID.Devnet)
      mainContract = new FarmServiceSolanaContract(
        mainProvider,
        SOL_CHAINID.MainnetBeta,
        pools.filter((x) => x.type === 'main')
      )
      devContract = new FarmServiceSolanaContract(
        devProvider,
        SOL_CHAINID.Devnet,
        pools.filter((x) => x.type === 'dev')
      )
    }

    switch ((provider?.connection as any)?._rpcEndpoint) {
      case (mainContract.provider.connection as any)?._rpcEndpoint:
        return mainContract
      case (devContract.provider.connection as any)?._rpcEndpoint:
        return devContract
    }
    return process.env.VUE_APP_FARM_NETWORK === 'production' ? mainContract : devContract
  }

  injectProvider(provider) {
    this.provider = provider
    this.program = new Program(farmServiceIDL as any, FARM_SERVICE_ID.toString(), provider)
    Object.values(this.tokenPrograms).forEach((t) => t.injectProvider(provider))
  }

  _loadTask?: Promise<any>
  _loaded = false
  async loadIfNeed() {
    if (this._loaded) {
      return Promise.resolve()
    }
    if (this._loadTask) {
      return this._loadTask
    }
    this._loadTask = this.load().catch(() => (this._loadTask = undefined))
    return this._loadTask
  }

  async load() {
    const [_stateSigner] = await anchor.web3.PublicKey.findProgramAddress(
      [utf8.encode('state')],
      this.program.programId
    )
    this.stateAccount = { ...(await this.program.account.stateAccount.fetch(_stateSigner)) }
    await this.getPoolConfigs()
  }

  async getPoolInfors(force = false) {
    if (force) await this.getPoolConfigs()
    return cloneDeep(this.poolConfigs)
  }

  async getUserInfors(account: string, force = false) {
    if (force) {
      const userAccounts: FarmUserAccount[] = (
        await this.program.account.poolUserAccount.all(new PublicKey(account).toBuffer())
      ).map((x) => ({
        publicKey: x.publicKey,
        ...x.account,
      }))
      this.userInfos = userAccounts
    }
    return Promise.all(
      this.poolConfigs.map(async (poolConfig) => {
        const { poolAccount } = await this.getPoolSigners(poolConfig)
        const userAccount = this.userInfos.find((ua) => ua.pool.equals(poolConfig.publicKey))
        return {
          created: !!userAccount,
          pool: poolConfig,
          contributeId: userAccount?.contributeId.toNumber(),
        } as FarmServiceUserInfo
      })
    )
  }

  async getUserContributeInfos(account: string, force = false) {
    if (force) {
      const contributes: FarmContributeAccount[] = (
        await this.program.account.poolContributeAccount.all(new PublicKey(account).toBuffer())
      ).map((x) => ({
        publicKey: x.publicKey,
        ...x.account,
      }))
      this.userContributeInfos = contributes
    }
    const contributes = this.userContributeInfos
    return Promise.all(
      this.poolConfigs.map(async (poolConfig) => {
        const { poolAccount } = await this.getPoolSigners(poolConfig)
        const contributeAccount = contributes.find((ua) => ua.pool.equals(poolConfig.publicKey))
        let lastStakeTime: Moment | null = null
        let lockDuration: Duration | null = null
        if (contributeAccount) {
          if (!contributeAccount.lastStakeTime.isZero()) {
            lastStakeTime = bnHelper.toMoment(contributeAccount.lastStakeTime)
          }
          if (!contributeAccount.lockDuration.isZero()) {
            lockDuration = duration(contributeAccount.lockDuration.toNumber(), 'second')
          }
        }
        const amount = bnHelper.fromSolDecimals(contributeAccount?.amount || '0', poolAccount.mintDeciamls)
        const pendingReward = await this.getPendingRewardAmount(poolConfig, account)
        return {
          pool: poolConfig,
          lastStakeTime,
          lockDuration,
          amount,
          pendingReward,
          id: contributeAccount?.id.toNumber(),
        } as FarmServiceContributeInfo
      })
    )
  }

  private async getPoolConfigs() {
    this.poolAccounts = (await this.program.account.poolAccount.all()).map((x) => ({
      ...x.account,
      publicKey: x.publicKey,
      name: utf8.decode(new Uint8Array(x.account.name)).trim(),
      encodedName: utf8.encode(utf8.decode(new Uint8Array(x.account.name)).trim()),
    }))
    this.poolConfigs = this.poolConfigs
      .map((x) => {
        const poolAccount = this.poolAccounts.find((pc) => pc.publicKey.equals(x.publicKey))
        if (poolAccount) {
          if (!this.tokenPrograms[poolAccount.mint.toString()]) {
            this.tokenPrograms[poolAccount.mint.toString()] = new SlpTokenProgram(poolAccount.mint, this.provider)
          }
          if (!this.tokenPrograms[poolAccount.rewardMint.toString()]) {
            this.tokenPrograms[poolAccount.rewardMint.toString()] = new SlpTokenProgram(
              poolAccount.rewardMint,
              this.provider
            )
          }
          const totalStakedAmount = bnHelper.fromSolDecimals(poolAccount.amount, poolAccount.mintDeciamls)
          const farmRate = bnHelper.fromSolDecimals(poolAccount.tokenPerSecond, poolAccount.rewardDecimals)
          const tokenOnYear = farmRate.mulUnsafe(FixedNumber.from(365 * 24 * 60 * 60))
          const apy = totalStakedAmount.isZero()
            ? totalStakedAmount
            : tokenOnYear.divUnsafe(totalStakedAmount).mulUnsafe(FixedNumber.from('100'))
          return {
            ...x,
            apy,
            totalStakedAmount,
            authority: poolAccount.authority.toString(),
            admin: this.stateAccount?.creator.toString(),
            tokenAddress: poolAccount.mint.toString(),
            tokensPerSecond: bnHelper.fromSolDecimals(poolAccount.tokenPerSecond, poolAccount.rewardDecimals),
          }
        } else {
          return null
        }
      })
      .filter((x) => !!x) as any
  }

  async createUser(pool: FarmServicePoolConfig, account: string) {
    const { poolAccount, extra } = await this.getPoolSigners(pool)
    const authority = new PublicKey(account)
    const { userAccount, bump } = await this.getUserSigner(poolAccount.name, account)
    try {
      await this.program.rpc.createPoolUser(bump, {
        accounts: {
          user: userAccount,
          state: await this.getStateSigner(),
          pool: poolAccount.publicKey,
          authority,
          systemProgram: SystemProgram.programId.toString(),
        },
      })
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmServiceIDL)
    }
  }

  async stake(
    pool: FarmServicePoolConfig,
    contributeId: number,
    account: string,
    amount: string,
    lockInSeconds: number
  ) {
    const { poolAccount, extra } = await this.getPoolSigners(pool)
    const authority = new PublicKey(account)
    const stakeAmount = bnHelper.toSolDecimal(amount, poolAccount.mintDeciamls)
    const contributeCreated = !!(await this.getContributeAccount(contributeId))
    const txs: Array<SendTxRequest> = []
    const { userAccount, bump } = await this.getUserSigner(poolAccount.name, account)
    const { contributeSigner, contributeBump } = await this.getContributeSigner(contributeId)
    const state = await this.getStateSigner()
    if (!contributeCreated) {
      const tx = this.program.transaction.createPoolContribute(new BN(contributeId), contributeBump, {
        accounts: {
          user: userAccount,
          contribute: contributeSigner,
          state,
          pool: poolAccount.publicKey,
          authority,
          systemProgram: SystemProgram.programId.toString(),
        },
      })
      txs.push({ tx, signers: [] })
    }
    const stakeTx = this.program.transaction.stake(new BN(contributeId), stakeAmount, new BN(lockInSeconds), {
      accounts: {
        mint: poolAccount.mint,
        poolVault: poolAccount.vault,
        userVault: await this.tokenPrograms[poolAccount.mint.toString()].getAssociatedTokenAddress(authority),
        extra,
        contribute: (await this.getContributeSigner(contributeId)).contributeSigner,
        pool: poolAccount.publicKey,
        authority: authority,
        tokenProgram: TOKEN_PROGRAM_ID,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        systemProgram: SystemProgram.programId,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      },
    })
    txs.push({ tx: stakeTx, signers: [] })
    try {
      await this.provider.sendAll(txs, {})
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmServiceIDL)
    }
  }

  async unstake(pool: FarmServicePoolConfig, contributeId: number, account: string, amount: string) {
    const { poolAccount, extra } = await this.getPoolSigners(pool)
    const authority = new PublicKey(account)
    const unstakeAmount = bnHelper.toSolDecimal(amount, poolAccount.mintDeciamls)
    const { userAccount, bump } = await this.getUserSigner(poolAccount.name, account)
    try {
      await this.program.rpc.unstake(new BN(contributeId), unstakeAmount, {
        accounts: {
          mint: poolAccount.mint,
          poolVault: poolAccount.vault,
          userVault: await this.tokenPrograms[poolAccount.mint.toString()].getAssociatedTokenAddress(authority),
          extra,
          contribute: (await this.getContributeSigner(contributeId)).contributeSigner,
          pool: poolAccount.publicKey,
          authority: authority,
          tokenProgram: TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        },
      })
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmServiceIDL)
    }
  }

  async harvest(pool: FarmServicePoolConfig, contributeId: number, account: string) {
    const { poolAccount, extra } = await this.getPoolSigners(pool)
    const authority = new PublicKey(account)
    const { userAccount, bump } = await this.getUserSigner(poolAccount.name, account)
    const state = await this.getStateSigner()
    try {
      await this.program.rpc.harvest(new BN(contributeId), {
        accounts: {
          rewardMint: poolAccount.rewardMint,
          rewardVault: poolAccount.rewardVault,
          userVault: await this.tokenPrograms[poolAccount.rewardMint.toString()].getAssociatedTokenAddress(authority),
          extra,
          contribute: (await this.getContributeSigner(contributeId)).contributeSigner,
          pool: poolAccount.publicKey,
          authority: authority,
          tokenProgram: TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        },
      })
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmServiceIDL)
    }
  }

  async updateTokensPerSecond(pool: FarmServicePoolConfig, tokensPerSecond: FixedNumber, account: string) {
    const { poolAccount } = await this.getPoolSigners(pool)
    const authority = new PublicKey(account)
    const state = await this.getStateSigner()
    try {
      await this.program.rpc.changePoolRate(bnHelper.toSolDecimal(tokensPerSecond, poolAccount.rewardDecimals), {
        accounts: {
          state,
          pool: poolAccount.publicKey,
          authority: authority,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        },
      })
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmServiceIDL)
    }
  }

  async fundReward(pool: FarmServicePoolConfig, account: string, amount: FixedNumber) {
    console.log(amount.toString())
    const authority = new PublicKey(account)
    const { poolAccount } = await this.getPoolSigners(pool)
    try {
      await this.program.rpc.fundRewardToken(bnHelper.toSolDecimal(amount), {
        accounts: {
          owner: authority,
          mint: poolAccount.rewardMint,
          pool: poolAccount.publicKey,
          rewardVault: poolAccount.rewardVault,
          userVault: await this.tokenPrograms[poolAccount.rewardMint.toString()].getAssociatedTokenAddress(authority),
          tokenProgram: TOKEN_PROGRAM_ID,
        },
      })
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmServiceIDL)
    }
  }

  async getPendingRewardAmount(pool: FarmServicePoolConfig, account: string) {
    const { poolAccount } = await this.getPoolSigners(pool)
    const contributeInfo = this.userContributeInfos.find(
      (u) => u.pool.equals(pool.publicKey) && u.authority.equals(new PublicKey(account))
    )!
    let amount = FixedNumber.from(0)
    try {
      if (contributeInfo) {
        let accRewardPerShare = poolAccount.accRewardPerShare
        if (!poolAccount.amount.isZero()) {
          const seconds = new BN(Date.now() / 1000).sub(poolAccount.lastRewardTime)
          const more = poolAccount.tokenPerSecond
            .mul(seconds)
            .mul(new BN(10).pow(new BN(11)))
            .div(poolAccount.amount)
          accRewardPerShare = accRewardPerShare.add(more)
        }
        let pending = contributeInfo.amount
          .mul(accRewardPerShare)
          .div(new BN(10).pow(new BN(11)))
          .sub(contributeInfo.rewardDebt)
        const configs = pool.extraConfigs
        const extraPercentage =
          [...configs].reverse().find((x) => contributeInfo.lockDuration.toNumber() >= x.duration.asSeconds())
            ?.extraPercentage || FixedNumber.from('0')
        const extraReward = pending.mul(new BN(extraPercentage.toUnsafeFloat())).div(new BN(100))
        // console.log(extraReward.toNumber(), userInfo.extraReward.toNumber())
        pending = pending.add(contributeInfo.rewardAmount).add(contributeInfo.extraReward).add(extraReward)
        if (pending.isNeg()) pending = new BN(0)
        amount = bnHelper.fromSolDecimals(pending, poolAccount.rewardDecimals)
      }
    } catch (e) {
      console.log(e)
    }
    return amount
  }

  async getUserTokenBalance(pool: FarmServicePoolConfig, account: string) {
    try {
      const { poolAccount } = await this.getPoolSigners(pool)
      return await this.tokenPrograms[poolAccount.mint.toString()].getTokenAmount(account)
    } catch (error) {
      return FixedNumber.from('0')
    }
  }

  async getUserRewardTokenBalance(pool: FarmServicePoolConfig, account: string) {
    try {
      const { poolAccount } = await this.getPoolSigners(pool)
      return await this.tokenPrograms[poolAccount.rewardMint.toString()].getTokenAmount(account)
    } catch (error) {
      return FixedNumber.from('0')
    }
  }

  async getRewardTokenBalance(pool: FarmServicePoolConfig) {
    try {
      const { poolAccount } = await this.getPoolSigners(pool)
      const acc = await this.tokenPrograms[poolAccount.rewardMint.toString()].token.getAccountInfo(
        poolAccount.rewardVault
      )
      return bnHelper.fromSolDecimals(acc.amount, poolAccount.rewardDecimals)
    } catch (error) {
      return FixedNumber.from('0')
    }
  }

  private async getPoolSigners(pool: FarmServicePoolConfig) {
    const poolAccount = this.poolAccounts.find((pa) => pa.publicKey.equals(pool.publicKey))!
    const [signer, poolBump] = await PublicKey.findProgramAddress([utf8.encode(poolAccount.name)], FARM_SERVICE_ID)
    const [vault, vaultBump] = await PublicKey.findProgramAddress(
      [utf8.encode(poolAccount.name), utf8.encode('vault')],
      FARM_SERVICE_ID
    )
    const [rewardVault, rewardBump] = await PublicKey.findProgramAddress(
      [utf8.encode(poolAccount.name), utf8.encode('reward')],
      FARM_SERVICE_ID
    )
    const [extra, extraBump] = await PublicKey.findProgramAddress(
      [utf8.encode(poolAccount.name), utf8.encode('extra')],
      FARM_SERVICE_ID
    )
    return { poolAccount, signer, poolBump, vault, vaultBump, rewardVault, rewardBump, extra, extraBump }
  }
  async getUserAccount(pool: FarmServicePoolConfig, account) {
    const { poolAccount } = await this.getPoolSigners(pool)
    const user = await this.getUserSigner(poolAccount.name, account)
    try {
      const userAccount = await this.program.account.poolUserAccount.fetch(user.userAccount)
      return { ...userAccount, publicKey: user.userAccount } as FarmUserAccount
    } catch (error) {
      return null
    }
  }
  async getContributeAccount(id) {
    const { contributeSigner } = await this.getContributeSigner(id)
    try {
      const contribute = await this.program.account.poolContributeAccount.fetch(contributeSigner)
      return { ...contribute, publicKey: contributeSigner } as FarmContributeAccount
    } catch (error) {
      return null
    }
  }
  // async getStateAccount() {
  //   const stateSigner = await this.getStateSigner()
  //   const info = (await this.program.account.stateAccount.fetch(stateSigner)) as FarmStateAccount
  //   return info
  // }
  // async getPoolAccount() {
  //   return (await this.program.account.farmPoolAccount.fetch(await this.getPoolSigner())) as FarmPoolAccount
  // }
  // async getRewardConfigSigner() {
  //   const [extraRewardSigner, extraRewardBump] = await anchor.web3.PublicKey.findProgramAddress(
  //     [utf8.encode('extra')],
  //     this.program.programId
  //   )
  //   return extraRewardSigner.toString()
  // }
  async getStateSigner() {
    const [_poolSigner] = await anchor.web3.PublicKey.findProgramAddress([utf8.encode('state')], this.program.programId)
    return _poolSigner
  }
  // async getPoolSigner() {
  //   const [_poolSigner] = await anchor.web3.PublicKey.findProgramAddress(
  //     [this.rewardToken.mint.toBuffer()],
  //     this.program.programId
  //   )
  //   return _poolSigner.toString()
  // }
  async getUserSigner(poolId: string, address: string) {
    const accountId = new PublicKey(address)
    const [userAccount, bump] = await PublicKey.findProgramAddress(
      [utf8.encode(poolId), accountId.toBuffer()],
      this.program.programId
    )
    return { userAccount: userAccount, bump }
  }

  async getContributeSigner(id) {
    const [contributeSigner, contributeBump] = await anchor.web3.PublicKey.findProgramAddress(
      [utf8.encode('contribute'), utf8.encode(id.toString())],
      this.program.programId
    )
    return { contributeSigner, contributeBump }
  }
}

export interface FarmStateAccount {
  creator: PublicKey
  bump: number
  tax: BN // maybe
}

export interface FarmPoolAccount {
  publicKey: PublicKey
  authority: PublicKey
  name: string
  encodedName: Uint8Array
  bumps: { pool: number; vault: number; reward: number }
  amount: BN
  mint: PublicKey
  vault: PublicKey
  rewardMint: PublicKey
  rewardVault: PublicKey
  lastRewardTime: BN
  accRewardPerShare: BN
  tokenPerSecond: BN
  totalUser: BN
  tax: BN // maybe
  paused: boolean
  mintDeciamls: number
  rewardDecimals: number
}

export interface FarmUserAccount {
  publicKey: PublicKey
  contributeId: u64
  bump: number
  pool: PublicKey
  authority: PublicKey
}

export interface FarmContributeAccount {
  id: u64
  publicKey: PublicKey
  bump: number
  pool: PublicKey
  authority: PublicKey
  amount: BN
  rewardAmount: BN
  rewardDebt: BN
  lastStakeTime: BN
  lockDuration: BN
  extraReward: BN
}

export interface RewardConfigAccount {
  authority: PublicKey
  configs: { duration: BN; extraPercentage: BN }[]
}
